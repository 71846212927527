import { Divider, Flex, Text, VStack } from "@chakra-ui/react";
import { PermissionEnum, useGetClientsForFolderQuery } from "generated/graphql";
import { useAuth } from "hooks/useAuth";
import { SidebarClientFolder } from "./SidebarClientFolder";
import { Link } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";

export const SidebarClientList = () => {
  const { data: clients } = useGetClientsForFolderQuery();
  const { managedClients, hasPermission } = useAuth();

  const ownClients = clients?.clients.filter((client) =>
    managedClients.find((c) => c.id === client.id)
  );

  const otherClients = clients?.clients.filter(
    (client) => !managedClients.find((c) => c.id === client.id)
  );

  return (
    <VStack spacing={1} align="stretch">
      {(ownClients?.length ?? 0) > 0 ? (
        <Text
          fontSize="xs"
          color="whiteAlpha.600"
          px={4}
          py={2}
          fontWeight="medium"
        >
          YOUR CLIENTS
        </Text>
      ) : null}

      {ownClients?.map((client) => (
        <SidebarClientFolder
          key={client.id}
          text={client.name}
          logoUrl={client.logo}
          fontSize="sm"
          clientId={client.id}
          isArchived={client.status === "ARCHIVED"}
        />
      ))}

      {hasPermission(PermissionEnum.CanViewAllClients) &&
        (otherClients?.length ?? 0) > 0 && (
          <>
            {(ownClients?.length ?? 0) > 0 && (
              <Divider my={2} opacity={0.2} width="80%" alignSelf="center" />
            )}

            <Text
              fontSize="xs"
              color="whiteAlpha.600"
              px={4}
              py={2}
              fontWeight="medium"
            >
              OTHER CLIENTS
            </Text>

            {otherClients?.map((client) => (
              <SidebarClientFolder
                key={client.id}
                text={client.name}
                logoUrl={client.logo}
                fontSize="sm"
                clientId={client.id}
                isArchived={client.status === "ARCHIVED"}
              />
            ))}
          </>
        )}

      {clients?.clients.length === 0 && (
        <Flex my={3} px={4} justify="center">
          {hasPermission(PermissionEnum.CanAddClients) ? (
            <Link to="/clients">
              <Text
                fontSize="sm"
                color="whiteAlpha.700"
                _hover={{
                  color: "white",
                  transform: "translateX(4px)",
                }}
                transition="all 0.2s"
                display="flex"
                alignItems="center"
              >
                <AddIcon mr={2} /> Add a client
              </Text>
            </Link>
          ) : (
            <Text fontSize="sm" color="whiteAlpha.600" fontStyle="italic">
              No clients available
            </Text>
          )}
        </Flex>
      )}
    </VStack>
  );
};
