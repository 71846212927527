import { Box, Image, List } from "@chakra-ui/react";
import { useAuth } from "../../hooks/useAuth";
import { SidebarLink } from "./SidebarLink";
import { routesWithPermissions } from "../../routes";
import { TOP_BAR_HEIGHT } from "./TopBar";
import { BsBuilding, BsNewspaper } from "react-icons/bs";
import {
  FaBookOpen,
  FaCog,
  FaNewspaper,
  FaSearch,
  FaTasks,
  FaUsers,
  FaVoicemail,
} from "react-icons/fa";
import { WiStars } from "react-icons/wi";
import { MdWork } from "react-icons/md";
import { SidebarLinkFolder } from "./SidebarLinkFolder";
import { SidebarSupportMenu } from "./SidebarSupportMenu";
import { SidebarClientList } from "./sidebar-clients/SidebarClientList";
import { Link } from "react-router-dom";
import Logo from "./assets/logo-red-transparent-1024.png";

export const Sidebar = () => {
  const { hasPermission } = useAuth();

  return (
    <Box
      backgroundColor="yaarnRed.500"
      boxShadow="lg"
      display="block"
      position="fixed"
      maxWidth="300px"
      color="white"
      zIndex={101}
      backdropFilter="blur(10px)"
      height="100%"
      transition="all 0.3s ease-in-out"
    >
      <Link to="/">
        <Image
          filter="brightness(0) invert(1)"
          h="10vh"
          p="4"
          src={Logo}
          transition="all 0.2s ease-in-out"
          _hover={{ transform: "scale(1.05)" }}
        />
      </Link>
      <Box
        overflowY="scroll"
        height={`calc(100vh - ${TOP_BAR_HEIGHT})`}
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            background: "rgba(255,255,255,0.1)",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(255,255,255,0.3)",
            borderRadius: "2px",
          },
        }}
      >
        <List my={6}>
          {/* Agency Section */}
          {hasPermission(
            routesWithPermissions["/agency-dashboard"].permission
          ) && (
            <>
              <SidebarLink
                onHover={() =>
                  routesWithPermissions["/agency-dashboard"].element.preload()
                }
                key="/agency-dashboard"
                icon={MdWork}
                to="/agency-dashboard"
                text="Agency Dashboard"
              />

              <SidebarLinkFolder
                id="client-manager"
                text="Clients"
                icon={BsBuilding}
              >
                <SidebarClientList />
              </SidebarLinkFolder>
            </>
          )}

          <SidebarLinkFolder
            id="news-agent"
            text="News Agent"
            icon={FaNewspaper}
          >
            {hasPermission(routesWithPermissions["/news-wraps"].permission) && (
              <SidebarLink
                onHover={() =>
                  routesWithPermissions["/news-wraps"].element.preload()
                }
                key="/news-wraps"
                icon={BsNewspaper}
                fontSize="sm"
                to="/news-wraps"
                text="News Wraps"
              />
            )}
            <SidebarLink
              onHover={() =>
                routesWithPermissions["/news-search"].element.preload()
              }
              key="/news-search"
              icon={FaSearch}
              fontSize="sm"
              to="/news-search"
              text="News Search"
            />
            {hasPermission(
              routesWithPermissions["/publications"].permission
            ) && (
              <SidebarLink
                fontSize="sm"
                onHover={() =>
                  routesWithPermissions["/publications"].element.preload()
                }
                key="/publications"
                icon={FaNewspaper}
                to="/publications"
                text="Publications"
              />
            )}
            {hasPermission(routesWithPermissions["/authors"].permission) && (
              <SidebarLink
                fontSize="sm"
                onHover={() =>
                  routesWithPermissions["/authors"].element.preload()
                }
                key="/authors"
                to="/authors"
                icon={FaVoicemail}
                text="Journalists"
              />
            )}
          </SidebarLinkFolder>

          {hasPermission(
            routesWithPermissions["/content-writer"].permission
          ) && (
            <SidebarLink
              onHover={() =>
                routesWithPermissions["/content-writer"].element.preload()
              }
              key="/content-writer"
              icon={WiStars}
              to="/content-writer"
              text={"Loom"}
            />
          )}

          {hasPermission(
            routesWithPermissions["/agency-dashboard"].permission
          ) && (
            <SidebarLinkFolder
              id="agency-settings"
              icon={FaCog}
              text="Settings"
            >
              {hasPermission(
                routesWithPermissions["/workspace"].permission
              ) && (
                <SidebarLink
                  fontSize="sm"
                  onHover={() =>
                    routesWithPermissions["/workspace"].element.preload()
                  }
                  key="/workspace"
                  icon={FaBookOpen}
                  to="/workspace"
                  text="Workspace"
                />
              )}
              {hasPermission(routesWithPermissions["/clients"].permission) && (
                <SidebarLink
                  fontSize="sm"
                  onHover={() =>
                    routesWithPermissions["/clients"].element.preload()
                  }
                  key="/client-settings"
                  icon={BsBuilding}
                  to="/clients"
                  text="Clients"
                />
              )}
              {hasPermission(routesWithPermissions["/users"].permission) && (
                <SidebarLink
                  fontSize="sm"
                  onHover={() =>
                    routesWithPermissions["/users"].element.preload()
                  }
                  key="/users"
                  icon={FaUsers}
                  to="/users"
                  text="Users"
                />
              )}
            </SidebarLinkFolder>
          )}

          {/* Divider - only show if user has both permissions */}
          {hasPermission(
            routesWithPermissions["/agency-dashboard"].permission
          ) &&
            hasPermission(
              routesWithPermissions["/client-dashboard"].permission
            ) && (
              <Box
                borderBottom="1px solid"
                borderColor="whiteAlpha.300"
                my={4}
              />
            )}

          {/* Client Section */}
          {hasPermission(
            routesWithPermissions["/client-dashboard"].permission
          ) && (
            <>
              <SidebarLink
                onHover={() =>
                  routesWithPermissions["/client-dashboard"].element.preload()
                }
                key="/client-dashboard"
                icon={MdWork}
                to="/client-dashboard"
                text="Client Dashboard"
              />

              {hasPermission(
                routesWithPermissions["/client/activities"].permission
              ) && (
                <SidebarLink
                  onHover={() =>
                    routesWithPermissions[
                      "/client/activities"
                    ].element.preload()
                  }
                  key="/client/activities"
                  icon={FaTasks}
                  to="/client/activities"
                  text="Activities"
                />
              )}
              {hasPermission(
                routesWithPermissions["/client/content-writer"].permission
              ) && (
                <SidebarLink
                  onHover={() =>
                    routesWithPermissions[
                      "/client/content-writer"
                    ].element.preload()
                  }
                  key="/client/content-writer"
                  icon={WiStars}
                  to="/client/content-writer"
                  text={"Loom"}
                />
              )}
              {hasPermission(
                routesWithPermissions["/client/coverage"].permission
              ) && (
                <SidebarLink
                  onHover={() =>
                    routesWithPermissions["/client/coverage"].element.preload()
                  }
                  key="/client/coverage"
                  icon={FaBookOpen}
                  to="/client/coverage"
                  text="Coverage"
                />
              )}
              {hasPermission(
                routesWithPermissions["/client/settings"].permission
              ) && (
                <SidebarLink
                  onHover={() =>
                    routesWithPermissions["/client/settings"].element.preload()
                  }
                  key="/client/settings"
                  icon={FaCog}
                  to="/client/settings"
                  text="Client Settings"
                />
              )}
            </>
          )}

          <Box borderBottom="1px solid" borderColor="whiteAlpha.300" my={4} />

          <SidebarSupportMenu key="feedback" />
        </List>
      </Box>
    </Box>
  );
};
