import {
  Box,
  Flex,
  Icon,
  ListItem,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Link, useLocation } from "react-router-dom";

interface SidebarLinkProps {
  to: string;
  text: string;
  icon: IconType;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  onHover?: () => void;
  fontSize?: string;
  py?: number;
  mx?: number | string;
}

interface InternalProps {
  text: string;
  icon: IconType;
  isOnLink: boolean;
  py?: number;
  fontSize?: string;
}

const Internal = ({ text, icon, isOnLink, py, fontSize }: InternalProps) => (
  <Box
    fontSize={fontSize}
    transition="all 0.2s ease-in-out"
    color={isOnLink ? "white" : "whiteAlpha.900"}
    backgroundColor={isOnLink ? "yaarnRed.200" : "transparent"}
    _hover={{
      backgroundColor: isOnLink ? "yaarnRed.400" : "yaarnRed.300",
      transform: "translateX(4px)",
    }}
    py={py ?? 3}
    px={3}
    borderRadius="md"
  >
    <Flex>
      <Box mr={3} display="inline">
        <Icon fontSize="sm" color={"white"} as={icon} />
      </Box>
      <Box fontWeight={isOnLink ? "extrabold" : "regular"} display="inline">
        <Text>{text}</Text>
      </Box>
    </Flex>
  </Box>
);

export const SidebarLink = ({
  to,
  text,
  icon,
  onClick,
  onHover,
  py,
  fontSize,
  mx = "4",
}: SidebarLinkProps) => {
  const location = useLocation();
  const isOnLink =
    to === "/" ? location.pathname === "/" : location.pathname.startsWith(to);

  const isExternal = to.startsWith("http");

  return (
    <ListItem onMouseEnter={onHover} my="1" mx={mx}>
      {isExternal ? (
        <ChakraLink
          _hover={{ textDecor: "none" }}
          target="_blank"
          onClick={onClick}
          href={to}
          isExternal
        >
          <Internal
            text={text}
            icon={icon}
            isOnLink={isOnLink}
            py={py}
            fontSize={fontSize}
          />
        </ChakraLink>
      ) : (
        <Link onClick={onClick} to={to}>
          <Internal
            text={text}
            icon={icon}
            isOnLink={isOnLink}
            py={py}
            fontSize={fontSize}
          />
        </Link>
      )}
    </ListItem>
  );
};
