import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { AuthenticatedProvider } from "./providers/AuthenticatedProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { NotificationsProvider } from "./providers/NotificationsProvider";
import * as Sentry from "@sentry/react";
import { browserTracingIntegration } from "@sentry/browser";

import "@fontsource/nunito/200.css";
import "@fontsource/nunito/300.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/500.css";
import "@fontsource/nunito/600.css";
import "@fontsource/nunito/700.css";
import "@fontsource/nunito/800.css";
import "@fontsource/nunito/900.css";

import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";
import { SidebarFolderContext } from "components/page-shell/SidebarFolderProvider";
import { VersionProvider } from "providers/VersionProvider";
import { ChatbotProvider } from "providers/ChatbotContext";
import { DemoProvider } from "providers/DemoProvider";

const container = document.getElementById("root");

if (!container) {
  throw new Error("No root element found");
}

const root = ReactDOM.createRoot(container);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30000, // Consider data fresh for 30 seconds
      cacheTime: 300000, // Keep unused data in cache for 5 minutes
    },
  },
});

Sentry.init({
  dsn: "https://925d7ec5fe94466e96985842019bc013@o4504908033687552.ingest.sentry.io/4504908035194880",
  integrations: [browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.05 : 0.0,
  replaysOnErrorSampleRate: process.env.NODE_ENV === "production" ? 1 : 0.0,
});

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <VersionProvider>
          <AuthenticatedProvider>
            <DemoProvider>
              <NotificationsProvider>
                <SidebarFolderContext.Provider value={{ folders: [] }}>
                  <ChatbotProvider>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </ChatbotProvider>
                </SidebarFolderContext.Provider>
              </NotificationsProvider>
            </DemoProvider>
          </AuthenticatedProvider>
        </VersionProvider>
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);
