import {
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  List,
  Box,
  ListItem,
  Text,
  useToast,
  Alert,
  AlertIcon,
  DrawerOverlay,
  Link,
  Icon,
} from "@chakra-ui/react";
import { ChatbotMessage } from "./ChatbotMessage";
import { ExpandingTextareaInput } from "./ExpandingTextareaInput";
import { useContext, useEffect, useState } from "react";
import React from "react";
import { v4 as uuid } from "uuid";
import { ChatbotContext } from "providers/ChatbotContext";
import { streamChat } from "services/streamAiResponse";
import { ProvisionalChatbotMessage } from "./ProvisionalChatbotMessage";
import { useGetSuggestedPromptsMutation } from "generated/graphql";
import { SuggestedPrompts } from "./SuggestedPrompts";
import { WiStars } from "react-icons/wi";

export interface ChatbotMessageType {
  id: string;
  from: "user" | "assistant";
  message: string;
}

interface ChatbotDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  messages: ChatbotMessageType[];
}

export const ChatbotDrawer = ({
  isOpen,
  onClose,
  messages,
}: ChatbotDrawerProps) => {
  const parent = React.useRef<HTMLUListElement>(null);
  const { setMessages } = useContext(ChatbotContext);
  const toast = useToast();
  const { mutateAsync: getSuggestedPrompts } = useGetSuggestedPromptsMutation();

  // Scroll to bottom on mount
  useEffect(() => {
    if (parent.current) {
      parent.current.scrollTop = parent.current.scrollHeight;
    }
  }, [messages]);

  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [provisionalMessage, setProvisionalMessage] = useState("");
  const [suggestedPrompts, setSuggestedPrompts] = useState<string[]>([]);
  const [readableStream, setReadableStream] =
    useState<ReadableStreamDefaultReader<Uint8Array> | null>(null);

  const handleSubmit = async (v: string) => {
    setSuggestedPrompts([]);

    const newMessages = [
      ...messages,
      { id: uuid(), from: "user" as const, message: v },
    ];
    setMessages(newMessages);
    setIsLoadingMessage(true);

    try {
      let message = "";

      setReadableStream(
        await streamChat({
          onStream: async (data) => {
            setProvisionalMessage((prev) => prev + data.text);
            message += data.text;

            if (data.isComplete) {
              setProvisionalMessage("");
              setMessages((prevMessages: ChatbotMessageType[]) => {
                const newMessage = {
                  id: uuid(),
                  from: "assistant" as const,
                  message,
                };

                return prevMessages.concat(newMessage);
              });
              setIsLoadingMessage(false);
              setSuggestedPrompts(
                (await getSuggestedPrompts({ input: message }))
                  .getSuggestedPrompts
              );
            }
          },
          bodyData: newMessages,
        })
      );
    } catch (error) {
      toast({
        title: "Error",
        description: (error as Error).message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const stopGenerating = () => {
    readableStream?.cancel();
    setMessages((prevMessages) => {
      const newMessage = {
        id: uuid(),
        from: "assistant" as const,
        message: provisionalMessage,
      };

      return prevMessages.concat(newMessage);
    });
    setProvisionalMessage("");
    setIsLoadingMessage(false);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent
        backdropFilter="blur(10px)"
        backgroundColor="whiteAlpha.900"
      >
        <DrawerCloseButton />
        <DrawerHeader>Yaarn Bot</DrawerHeader>

        <DrawerBody p={0}>
          <List ref={parent} overflow="scroll">
            {messages.length === 0 && (
              <ListItem>
                <Box textAlign="center">
                  <Text>
                    Here for a yarn? You can ask Yaarn Bot about anything.
                  </Text>
                  <Text>
                    Type a message below and Yaarn Bot will generate a response.
                  </Text>
                </Box>

                <Box mt="6" ml="12">
                  <Text>Some examples:</Text>

                  <List>
                    {[
                      "How to handle a PR crisis?",
                      "Can you provide tips on public relations?",
                      "Can you explain the role of social media in public relations?",
                      "What are some effective PR strategies?",
                      "What are some top PR firms globally?",
                      "How to write a press release?",
                      "What are some examples of successful PR campaigns?",
                      "How do I measure the success of a PR campaign?",
                      "How do ethics play a role in public relations?",
                      "Can you provide some guidelines for interacting with media/journalists?",
                    ].map((v) => (
                      <ListItem key={v} onClick={() => handleSubmit(v)}>
                        <Link textDecor="underline">
                          <Icon mr="4" as={WiStars} />
                          {v}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Box mt="6" textAlign="center">
                  <Text>
                    Yaarn Bot is powered by generative AI. It may not be able to
                    answer questions about recent events. It also may not be
                    able to answer questions about very specific topics.
                  </Text>
                </Box>
                <Box mx="6">
                  <Alert fontSize="sm" my="6" status="warning">
                    <AlertIcon />
                    Always fact-check content, claims and data created with
                    Yaarn Bot. AI is not always accurate and may falsify
                    information.
                  </Alert>
                </Box>
              </ListItem>
            )}
            {messages.map(({ from, message, id }) => (
              <ChatbotMessage key={id} from={from} message={message} />
            ))}
            {isLoadingMessage && (
              <ProvisionalChatbotMessage
                onStopGenerating={stopGenerating}
                message={provisionalMessage}
              />
            )}
            {suggestedPrompts.length > 0 && (
              <SuggestedPrompts
                suggestedPrompts={suggestedPrompts}
                onSuggestedPromptClick={(prompt) => {
                  stopGenerating();
                  handleSubmit(prompt);
                }}
              />
            )}
            <Box h="80px"></Box>
          </List>
          <Box backgroundColor="gray.100" w="100%" position="fixed" bottom="0">
            <ExpandingTextareaInput
              isGenerating={isLoadingMessage}
              onSubmit={handleSubmit}
              placeholder="Send a message..."
            />
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
